'use client';

import '@/styles/globals.css';
import '@/styles/atcui.scss';
import '@/styles/index.scss';
import { roboto } from '@/styles/fonts';
import Script from 'next/script';
import React from 'react';
import { psxStaticUtilUrl } from '@/constants/config';
import { store } from '../stores/store';
import {
  Provider as FeatureFlagsContextProvider,
} from 'reaxl-features';
import { Provider as ReduxProvider } from 'react-redux';
import optimizelyClient from '../utilities/optimizelyClient';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { AnalyticsEngineConfigured} from '@/analytics/analyticsEngine';
import consumerId from '../utilities/myConsumerId';

export default function RootLayout({
 // Layouts must accept a children prop.
 // This will be populated with nested layouts or pages
 children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <head>
        <Script
          async
          strategy="afterInteractive"
          type="text/javascript"
          src="https://www.autotrader.com/psx-static/main/psx-react-web/js/newrelic.js"
        />
        <Script
          async
          strategy="afterInteractive"
          src="https://unpkg.com/mitt/dist/mitt.umd.js"
        />
        <Script
          async
          strategy="beforeInteractive"
          type="module"
          src={psxStaticUtilUrl}
        />
        <link rel="preconnect" href="//www.autotrader.com" crossOrigin="anonymous"/>
        <link rel="dns-prefetch" href="//www.autotrader.com" crossOrigin="anonymous"/>
        <link
          rel="preconnect"
          href="https://esm.sh"
        />
        <link
          rel="dns-prefetch"
          href="https://esm.sh"
        />
        <link
          rel="dns-prefetch"
          href="https://www.googletagmanager.com"
        />
        <link
          rel="dns-prefetch"
          href="https://gateway.foresee.com"
        />
        <link
          rel="dns-prefetch"
          href="https://cdn.cookielaw.org/"
        />
      </head>
      <body className={roboto.className}>
        <OptimizelyProvider
          optimizely={optimizelyClient}
          user={{ id: consumerId }}
        >
          <ReduxProvider store={store}>
            <AnalyticsEngineConfigured />
            <FeatureFlagsContextProvider>
              {children}
            </FeatureFlagsContextProvider>
          </ReduxProvider>
        </OptimizelyProvider>
      </body>
    </html>
  );
}
